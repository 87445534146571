@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Hussar";
  src: url("./HussarNova55.eot?") format("eot"),
    url("./HussarNova55.ttf") format("truetype"),
    url("./HussarNova55.svg#HussarNovaRg") format("svg");
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(56, 56, 56);
}


/* CSS reset */
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
form,
fieldset,
input,
textarea,
p,
blockquote,
th,
td {
  margin: 0;
  padding: 0;
}
html,
body {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset,
img {
  border: 0;
}
input {
  border: 1px solid #b0b0b0;
  padding: 3px 5px 4px;
  color: #979797;
  width: 190px;
}
address,
caption,
cite,
code,
dfn,
th,
var {
  font-style: normal;
  font-weight: normal;
}
ol,
ul {
  list-style: none;
}
caption,
th {
  text-align: left;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}
q:before,
q:after {
  content: "";
}
abbr,
acronym {
  border: 0;
}
